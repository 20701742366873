<template>
  <div class="kueche">
    <div class="row">
      <div class="flex md12">
        <va-card outlined>
          <template slot="header">
            <h5 class="mt-0 mb-0">{{ $t('kitchen.title') }}</h5>
          </template>
          <div class="row">
            <va-notification class="flex md10" v-if="currState.msgType=='success'" color="success">
              <va-badge color="success">
                {{ $t('common.notificationSuccess') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
            <va-notification class="flex md10" v-if="currState.msgType=='error'" color="danger">
              <va-badge color="danger">
                {{ $t('common.notificationError') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
          </div>
        </va-card>
      </div>
    </div>
    <va-modal v-model="toDelete.showDeleteModal"
      :message="toDelete.msg"
      title="$t('anmelden.signout')"
      :ok-text="$t('common.yes')"
      :cancel-text="$t('common.no')"
      @ok="deleteUserKiEvent()"/>
    <div class="row" v-for="(ev,evK) in workEventList" :key="evK">
      <div class="flex md12">
        <va-card :color="ev.workersCountMax > 0 ? '#cceecc' : ''" id="va-card-kitchen">
          <div class="row">
            <div class="flex md2">
              {{ev.eventStart}} - {{ev.eventEnd}}
            </div>
            <div class="flex md3">
              {{ev.eventDescr}}
            </div>
            <div class="flex md2 flex-center">
              angemeldet: Woche: {{ev.sumWorkersCount}} Pers.
            </div>
            <div class="flex md1">
              <va-button small v-if="!ev.workersCountMax" @click="signup(ev)"> {{ $t('anmelden.signUp') }}</va-button>
              <va-button small v-if="ev.workersCountMax" @click="saveUserKitchenEvent(ev)"> {{ $t('common.save') }}</va-button>
            </div>
            <div class="flex">
              <va-button small v-if="ev.workersCountMax" color="danger" @click="askDeleteEvent(ev)"> {{ $t('anmelden.signOut') }}</va-button>
            </div>
          </div>
          <div v-if="ev.workersCountMax" class="va-table-responsive">
            <table class="va-table" id="va-table-kitchen">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>{{ $t('common.date') }}</th>
                  <th>{{ $t('kitchen.breakfast') }}</th>
                  <th>{{ $t('kitchen.lunch') }}</th>
                  <th>{{ $t('kitchen.supper') }}</th>
                  <th>{{ $t('kitchen.cake') }}</th>
                  <th>{{ $t('kitchen.count') }}</th>
                  <th>{{ $t('kitchen.users') }}</th>
                  <th>{{ $t('common.comment') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="subs in ev.kitchenEventsDet" :key="subs.idxKey">
                  <td>
                    <va-button small color="info" @click="addNewSub(ev,subs)" class="mr-3 pr-2 pl-2">+</va-button>
                  </td>
                  <td>{{ subs.eventDay }}</td>
                  <td><va-checkbox v-model="subs.breakfast" /></td>
                  <td><va-checkbox v-model="subs.lunch" /></td>
                  <td><va-checkbox v-model="subs.supper" /></td>
                  <td><va-checkbox v-model="subs.cake" /></td>
                  <td>
                    <va-button small color="gray" @click="signupDecr(subs)" class="mr-3 pr-2 pl-2">-</va-button>
                    <b>{{ subs.workersCount }}</b>&nbsp;
                    <va-button small color="gray" @click="signupIncr(subs)" class="ml-3 pr-2 pl-2">+</va-button>
                  </td>
                  <td><va-input v-model="subs.userName" class="input-kueche" :placeholder="currentUser" /></td>
                  <td><va-input v-model="subs.comments" class="input-kueche" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'kueche',
  data () {
    this.$srvconn.sendMessage('getUserKitchenEvents')
      .then(response => {
        if (response.msgType === 'getUserKitchenEvents') {
          this.workEventList = response.userData.kitchenEvents
        }
        // console.log(response)
      })
      .catch(err => {
        console.log(err)
      })

    return {
      workEventList: {},
      currState: {
        msgType: '',
        msg: '',
      },
      toDelete: {
        showDeleteModal: false,
        msg: '',
      },
      currentUser: this.$userauth.loggedInUser(),
    }
  },
  methods: {
    mounted () {
    },
    askDeleteEvent (ev) {
      this.toDelete.msg = ev.eventDescr + '  -  ' + this.$t('anmelden.reallySignout')
      this.toDelete.userEvent = ev
      this.toDelete.showDeleteModal = true
    },
    deleteUserKiEvent () {
      if (this.toDelete.userEvent) {
        var usrData = {
          eventID: this.toDelete.userEvent.eventID,
        }
        this.$srvconn.sendMessage('deleteUserKiEvent', usrData)
          .then(response => {
            if (response.msgType === 'error') {
              this.currState.msgType = 'error'
              this.currState.msg = response.userData
            } else {
              this.currState.msgType = 'success'
              this.currState.msg = this.$t('common.saveSuccess')
              this.toDelete.userEvent.workersCountMax = 0
              this.toDelete.userEvent = null
              this.toDelete.msg = ''
            }
          })
          .catch(err => {
            console.log('ERROR: ' + err)
            this.currState.msgType = 'error'
            this.currState.msg = err
          })
      }
    },
    signupIncr (subs) {
      subs.workersCount += 1
    },
    signupDecr (subs) {
      if (subs.workersCount > 0) {
        subs.workersCount -= 1
      }
    },
    addNewSub (ev, subs) {
      var newSub = {
        keyIdx: ev.kitchenEventsDet.length,
        userKiEventID: -1,
        kiEventID: subs.kiEventID,
        eventDay: subs.eventDay,
        breakfast: false,
        lunch: false,
        supper: false,
        cake: false,
        workersCount: 1,
        userName: '',
        comments: '',
      }
      for (var i = 0; i < ev.kitchenEventsDet.length; i++) {
        if (ev.kitchenEventsDet[i].eventDay === subs.eventDay) {
          ev.kitchenEventsDet.splice(i + 1, 0, newSub)
          break
        }
      }
    },
    signup (ev) {
      var usrData = {
        eventID: ev.eventID,
      }
      this.$srvconn.sendMessage('signupKitchenEvent', usrData)
        .then(response => {
          if (response.msgType === 'error') {
            this.currState.msgType = 'error'
            this.currState.msg = response.userData
          } else {
            this.currState.msgType = 'success'
            this.currState.msg = this.$t('common.saveSuccess')
            ev.kitchenEventsDet = response.userData.kitchenEventsDet
            ev.workersCountMax = 1
          }
        })
        .catch(err => {
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
    saveUserKitchenEvent (ev) {
      this.$srvconn.sendMessage('saveUserKitchenEvent', ev)
        .then(response => {
          if (response.msgType === 'error') {
            this.currState.msgType = 'error'
            this.currState.msg = response.userData
          } else {
            this.currState.msgType = 'success'
            this.currState.msg = this.$t('common.saveSuccess')
            ev.kitchenEventsDet = response.userData.kitchenEventsDet
          }
        })
        .catch(err => {
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
  },
}
</script>

<style>
  #va-table-kitchen td {
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid #dddddd;
  }

  #va-table-kitchen {
    min-height: 0 !important;
    text-align: center;
  }

  .input-kueche {
    margin: 0;
  }

  #va-table-kitchen .va-checkbox__input-container {
    justify-content: center;
  }

  .input-kueche .va-input__container {
    min-height: 1rem;
    border-radius: 0;
  }

  .kueche .va-card {
    color: #000000 !important;
  }
</style>
